<template>
	<div class="d-flex">
		<div style="min-width: 80px; max-width: 150px" class="ml-2">
			<QuantityInput
				hide-details
				:disabled="pageLoading"
				:loading="pageLoading"
				v-model="discount_value"
				type="number"
				class="mt-0"
				v-on:keyup="getDiscount()"
				v-on:change="getDiscount()"
			></QuantityInput>
		</div>
		<div style="max-width: 70px" class="">
			<AutoCompleteInput
				v-if="false"
				hide-details
				:items="discountTypeList"
				:disabled="pageLoading"
				:loading="pageLoading"
				v-model="discount_value_type"
				class="mt-0 text-center"
				v-on:change="getDiscount()"
				v-on:keypress="$emit('keypress', $event)"
				v-on:keyup="$emit('keyup', $event)"
				v-on:keydown="$emit('keydown', $event)"
			></AutoCompleteInput>
			<v-autocomplete
				hide-details
				outlined
				:items="discountTypeList"
				:disabled="pageLoading"
				:loading="pageLoading"
				v-model="discount_value_type"
				class="mt-0 text-center"
				v-on:change="getDiscount()"
				v-on:keypress="$emit('keypress', $event)"
				v-on:keyup="$emit('keyup', $event)"
				v-on:keydown="$emit('keydown', $event)"
			></v-autocomplete>
		</div>
		<div style="max-width: 70px" class="">
			<AutoCompleteInput
				v-if="false"
				hide-details
				:items="discount_ve_list"
				:disabled="pageLoading"
				:loading="pageLoading"
				v-model="discount_ve"
				class="mt-0 text-center"
				v-on:change="getDiscount()"
				v-on:keypress="$emit('keypress', $event)"
				v-on:keyup="$emit('keyup', $event)"
				v-on:keydown="$emit('keydown', $event)"
			></AutoCompleteInput>
			<v-autocomplete
				hide-details
				outlined
				:items="discount_ve_list"
				:disabled="pageLoading"
				:loading="pageLoading"
				v-model="discount_ve"
				class="mt-0 text-center"
				v-on:change="getDiscount()"
				v-on:keypress="$emit('keypress', $event)"
				v-on:keyup="$emit('keyup', $event)"
				v-on:keydown="$emit('keydown', $event)"
			></v-autocomplete>
		</div>
	</div>
</template>
<script>
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { toNumber } from "lodash";
export default {
	name: "price-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		id: {
			type: String,
			default: null,
		},
		maxDiscount: {
			type: [Number, String],
			default: 0,
		},
		discountType: {
			type: Number,
			default: 1,
		},
		discountVe: {
			type: String,
			default: 'positive',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageLoading: false,
			discount_value_type: 1,
			discount_ve:'negative',
			discount_value: 0,
			discountTypeList: [
				{ text: "$", value: 1 },
				{ text: "%", value: 2 },
			],
			discount_ve_list: [
				{ text: "+VE", value: 'positive' },
				{ text: "-VE", value: 'negative' },
			],
		};
	},
	watch: {
		value(param) {
			this.discount_value = param;
		},
		discount_value() {
			this.$emit("input", this.discount_value);
		},
		discountType(param) {
			this.discount_value_type = param;
		},
		discountVe(param) {
			this.discount_ve = param;
		},
	},
	methods: {
		validateNumber(value) {
			const pattern = /^(\d+(?:,\d{1,2})?).*/;
			return pattern.test(value);
		},
		getDiscount() {
			if (this.discount_value != 0) {
				if (!this.validateNumber(this.discount_value)) {
					this.discount_value = 0;
					return false;
				}
			}
			const subtotal = toNumber(this.max_discount);
			if (this.discount_value_type == 1) {
				//$
				if (!this.discount_value || toNumber(this.discount_value) > toNumber(subtotal)) {
					this.discount_value = 0;
					//return false;
				}
			} else if (this.discount_value_type == 2) {
				//%
				if (!this.discount_value || toNumber(this.discount_value) > 100) {
					this.discount_value = 0;
					//return false;
				}
			}
			
			this.$emit("input", this.discount_value);
			this.$emit("update:type", this.discount_value_type);
			this.$emit("update:type_ve", this.discount_ve);
			this.$emit("change", this.discount_value);
		},
	},
	computed: {
		max_discount() {
			return +this.maxDiscount;
		},
	},
	components: {
		AutoCompleteInput,
		QuantityInput,
	},
	mounted() {
		this.discount_value = this.value;
		this.discount_value_type = this.discountType;
		this.discount_ve = this.discountVe;
	},
};
</script>
